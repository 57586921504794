/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component, Fragment } from "react";

import Editor from './Editor'
import Info from './Info';


class Tabs extends Component {
  state = {
    height:0,
    tab:"info",
  };

  onValueChange(val) {
    console.log(val);
    this.setState({
      tab: val
    });
  }

  render() {
    return (
      <div id="Tabs" style={{height: this.props.vh + "px"}}>

        <div className="tabs" onChange={this.onChangeValue}>
          <label className="tab" data-active={this.state.tab === "info"}>
            <input 
            type="radio" 
            name="tabs" 
            id="tab1" 
            value="info" 
            checked={this.state.tab === "info"}
            onChange={()=>this.onValueChange("info")}
            />
            Info
          </label>
          <label className="tab" data-active={this.state.tab === "editor"}>
            <input
            type="radio"
            name="tabs"
            id="tab2"
            value="editor"
            checked={this.state.tab === "editor"}
            onChange={()=>this.onValueChange("editor")}
            />
            Editor
          </label>
        </div>
        
        <div className="tab-content" data-active={this.state.tab === "info"}>
          <Info/>
        </div>
        <div className="tab-content" data-active={this.state.tab === "editor"}>
          <Editor/>
        </div>
        
      </div>
    );
  }
}

export default Tabs