/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import Tabs from "./Tabs";

import "./index.css";

class App extends Component {
  state = {
    height:0,
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    //window.addEventListener("orientationchange", this.updateDimensions);
    this.updateDimensions()

    //setTimeout(()=>this.updateInput("abc"), 3000)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    //window.removeEventListener("orientationchange", this.updateDimensions);
  }  

  updateDimensions = () => {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    this.setState({ vh, vw })
  }

  render() {
    
    return (
      <div id="container" style={{height: this.state.vh + "px"}}>
        <Tabs vh={this.state.vh}/>
      </div>
    );
  }
}

render(<App />, document.getElementById("root"));
