/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component, Fragment } from "react";
import { render } from "react-dom";

const symbolsInfo = {
  'I' : 'Person in the center of the narrative',
  'U' : 'The other person in the relationship',
  '←→': 'Arrow that indicate the direction of forces and attention',
  '{}': 'The void. Can stand alone or between around things. It relativizes the existence of what is inside. The unspeakable, The "problem", abyss, miss-understanding or non-understandable.',
  '[]': 'Square bracket puts things into imagination. Example: [U] - imagination of the other person.',
  '☼' : 'The sun. A warm force that shines on all of us. Sometimes admired.',
  '☀' : 'The hot sun. Burns, is dangerous. Physical, emotional.',
  '()': 'Brackets to group or encapsulate things',
  '⋯' : 'A casual connection',
  '↻' : 'Circling',
  '☠︎' : 'Very negative, dead end.',
  '=' : 'Equals',
  '⏱': 'Time',
  '⤳' : 'The Consequence',
}

const examples = [
  {
    imgUrl: '/example_board1.jpg',
  },
  {
    imgUrl: '/example_board2.jpg',
  },
  {
    imgUrl: '/example_board3.jpg',
  },
  {
    imgUrl: '/example_mirror.jpg',
  },
  {
    imgUrl: '/example_multi.png',
  },
]

const infoText = {
  intro: <p>Voidscript is way to describe and work on (inter)personal relationships via symbols.<br/><br/> Start by placing "I" and "U", put directions in between or brackets around and try to put the essence of the relationship into a formula. Each line represents a state at a fixed moment in time. Try to place the void at some point.</p>,
  outro: <p>A project by <a href="http://intergestalt.info">intergestalt</a> developed for and performed at <a href="https://vorspiel.berlin/?fbclid=IwAR3n4UBvhNncNhTA1hjbbFybeqPgKG-_0qjHJoR2q1jhVabdTiWHhdElBlg#overlay=node/55070/modal">A SHARED VOID</a>, 25.01.2020, Transmediale Vorspiel @ Hack Manufaktur</p>
}

class Info extends Component {
  render() {
    return (
      <div id="Info" style={{height: this.props.vh + "px"}}>
        <h2>Voidscript &nbsp; <span style={{fontWeight:"normal"}}>
          {`{ ☼ → U }`}
          </span></h2>
        {infoText.intro}
        <dl>
          {Object.entries(symbolsInfo).map( ([key, value]) => <Fragment key={key}><dd>{key}</dd><dt>{value}</dt></Fragment>)}
        </dl>
        {infoText.outro}
        <hr />
        <h2>Examples</h2>
          {examples.map( (example, index) => <img key={index} src={example.imgUrl} />)}
        <br /><br />
        <hr />
        <br />
        <h2>Testimonials</h2>
        <img src="/testimonial.jpg" />
      </div>
    );
  }
}

export default Info