/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component, Fragment } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

class Editor extends Component {
  state = {
    layoutName: "default",
    input: "",
    slot: [" ", " ", " ", " ", " ", " ", " "],
  };

  componentDidUpdate() {
    this.keyboard.setInput(this.state.input)
  }

  updateInput = (input) => 
    this.setState({
      input: input
    }
  );

  onChange = input => {
    this.updateInput(input)
    console.log("Input changed", input);
  };

  onKeyPress = button => {
    console.log("Button pressed", "'"+button+"'");

    //if (button === "{enter}") {
    //  this.setState({
    //    input: this.state.input + "\n"
    //  },()=>{
    //    this.keyboard.setInput(this.state.input);
    //  })
    //}

    //if (button === '' || this.state.slot.indexOf(button) > -1) {
    //  console.log("new key")
    //  this.setState({
    //    keyEnter:"",
    //    keyEnterOpen:true,
    //    input:"",
    //    disabled: false,
    //  })
    //}

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = event => {
    let input = event.target.value;
    console.log(event.target.selectionStart, event.target.selectionEnd)
    this.updateInput(input)
  };

  render() {

    const slot = this.state.slot
    
    const layout = {
      'default': [
      `A B C ${slot[0]}${slot[1]}${slot[2]}${slot[3]}${slot[4]}${slot[5]} X Y Z {bksp}`,
      '{tab} ♡ I { } ☼ ⏱ = U {bksp}', 
      '{lock} ( ) → ← ⋯ < > , {enter}',
      '{shift} ↻ - / | ☀ ☠︎ ⤳ {shift}',
      '{space}'
      ],
      'shift': [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : " {enter}',
        '{shift} Z X C V B N M < > ? {shift}',
        '{space}'
      ]
    }

    return (
      <div id="Editor">
        <textarea
          ref="textarea"
          disabled={this.state.disabled}
          value={this.state.input}
          placeholder={"Use virtual keyboard enter voidscript"}
          onChange={e => this.onChangeInput(e)}
        />
        <Keyboard
          layout = {layout}
          keyboardRef={r => (this.keyboard = r)}
          layoutName={this.state.layoutName}
          onChange={input => this.onChange(input)}
          onKeyPress={button => this.onKeyPress(button)}
          newLineOnEnter={true}
        />
      </div>
    );
  }
}

export default Editor